import { Route } from '@angular/router';
import { ExternalRedirectGuard } from '@guards/external-redirect.guard';
import { environment } from '../../environments/environment';

export const PAGES_ROUTES: Route[] = [
  {
    path: 'join',
    loadChildren: () =>
      import('./join-challenge/join-challenge.routes').then(m => m.JOIN_CHALLENGE_ROUTES),
  },
  {
    path: 'quiz-results',
    loadComponent: () =>
      import('./quiz-results/quiz-results.component').then(c => c.QuizResultsComponent),
  },
  {
    path: '40off',
    loadChildren: () => import('./sale-landing/sale-landing.module').then(m => m.SaleLandingModule),
  },

  {
    path: 'sale-popup-mobile',
    loadChildren: () =>
      import('./sale-popup-mobile/sale-popup-mobile.routes').then(m => m.SALE_POPUP_MOBILE_ROUTES),
  },
  {
    path: 'thankyou',
    loadComponent: () =>
      import('./paypal-thankyou/paypal-thankyou.component').then(c => c.PaypalThankyouComponent),
  },
  {
    path: 'app/competition/new',
    canActivate: [ExternalRedirectGuard],
    children: [],
    data: {
      externalUrl: 'https://stretchitapp.com/competition',
    },
  },
  {
    path: 'sandbox',
    canActivate: [() => !environment.production],
    loadChildren: () => import('./sandbox/sandbox-routes').then(m => m.SANDBOX_ROUTES),
  },
] as const;
