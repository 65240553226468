import { CanActivateFn } from '@angular/router';
import { wait } from '../shared/helpers/wait';

export const ExternalRedirectGuard: CanActivateFn = async route => {
  const externalUrl = route.paramMap.get('externalUrl') || route.data['externalUrl'];
  if (!externalUrl) {
    return false;
  }
  window.open(externalUrl, '_self');
  await wait(60_000);
  return false;
};
