import { Routes } from '@angular/router';
import { PAGES_ROUTES } from './pages/pages-routes';
import { ExternalRedirectGuard } from '@guards/external-redirect.guard';
import { DEPRECATED_PAGES_ROUTES } from './pages/deprecated-pages-routes';
import { PROGRAM_LANDING_ROUTES } from './pages/program-landing/program-landing.routes';
import { AuthorizationGuard } from './auth/guards/authorization.guard';
import { LicenseResolver } from './resolvers/license/license.resolver';
import { SaleGuard } from '@guards/sale.guard';
import { SaleMatcher } from './shared/matchers/sale.matcher';
import { WelcomeGuard } from '@guards/welcome.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/app/home',
    pathMatch: 'full',
  },

  // LICENSE
  {
    path: 'app/license/activate/:id',
    resolve: {
      license: LicenseResolver,
    },
    children: [],
  },
  {
    path: 'app/license/:type/activate/:id',
    resolve: {
      license: LicenseResolver,
    },
    children: [],
  },

  // MAIN APP
  {
    path: 'app',
    loadChildren: () => import('./dashboard/dashboard-routes').then(m => m.DASHBOARD_ROUTES),
  },

  // REDEEM
  {
    path: 'redeem',
    loadComponent: () => import('./dashboard/redeem/redeem.component').then(m => m.RedeemComponent),
  },
  {
    path: 'influencer_flow',
    loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent),
    loadChildren: () =>
      import('./dashboard/redeem/influencer-flow-routes').then(m => m.INFLUENCER_FLOW_ROUTES),
    data: {
      disableSalePopup: true,
    },
  },

  // SALE
  {
    path: 'sale',
    canActivate: [SaleGuard],
    children: [],
  },
  {
    matcher: SaleMatcher,
    canActivate: [SaleGuard],
    children: [],
  },

  // WELCOME
  {
    path: 'welcome',
    canActivate: [WelcomeGuard],
    children: [],
  },

  // AUTH
  {
    path: 'auth',
    loadComponent: () => import('./auth/auth.component').then(m => m.AuthComponent),
    loadChildren: () => import('./auth/auth-routes').then(m => m.AUTH_ROUTES),
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
  },

  // CERTIFICATE
  {
    path: 'gift',
    loadComponent: () =>
      import('./pages/gift-certificate/gift.component').then(m => m.GiftComponent),
    loadChildren: () =>
      import('./pages/gift-certificate/gift-certificate-routes').then(
        m => m.GIFT_CERTIFICATE_ROUTES
      ),
  },

  ...PAGES_ROUTES,
  ...PROGRAM_LANDING_ROUTES,
  ...DEPRECATED_PAGES_ROUTES,

  // REDIRECT TO EXTERNAL URL
  {
    path: 'externalRedirect',
    canActivate: [ExternalRedirectGuard],
    children: [],
  },

  // NOT FOUND
  {
    path: '**',
    loadComponent: () => import('./pages/404/not-found.component').then(m => m.NotFoundComponent),
  },
] as const;
