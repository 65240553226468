import 'hammerjs';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication, HammerModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpXhrBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { APP_ROUTES } from './app/app-routes';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import objectSupport from 'dayjs/plugin/objectSupport';
import { registerLocaleData } from '@angular/common';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { provideNgxStripe } from 'ngx-stripe';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  AppConfiguration,
  ENVIRONMENT_TOKEN,
} from './app/core/services/configuration/app.configuration';
import { NavigationService } from './app/core/services/navigation/navigation.service';
import { COMMON_BASE_URL } from './app/core/tokens/common-base-url.token';
import { STRETCHITLIVE_PIXEl_URL } from './app/core/tokens/stretchitlive-pixel-url.token';
import { SIZES_TOKEN } from './app/core/tokens/sizes.token';
import { AppSizes } from './config/sizes';
import { API_NEW_BASE_URL, PROGRAMS_NEW_BASE_URL } from './app/core/tokens/new-base-url.token';
import { FEEDBACK_URL } from './app/core/tokens/feedback-url.token';
import { AccessTokenInterceptorService } from './app/core/services/access-token-interceptor/access-token-interceptor.service';
import { LocaleInterceptor } from './app/core/interceptors/locale.interceptor';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';

if (environment.production) {
  enableProdMode();
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(objectSupport);
dayjs.extend(duration);

declare let fbq: any;

function initializeNavigationFactory(navigation: NavigationService): () => void {
  return () => navigation.init();
}

function initializeFacebookPixelFactory(config: AppConfiguration): () => void {
  return () => {
    const chargebeeQuizzes = ['quiz93', 'quiz94'].find(quiz => window.location.href.includes(quiz));

    let id: string;

    if (chargebeeQuizzes) {
      id = config.pixels.fbPixelIDChargebee;
    } else {
      id = config.pixels.fbPixelIdStripe;
    }

    fbq('init', id);
  };
}

// declare let ga: any;
// function initializeGoogleAnalyticsFactory(config: AppConfiguration): () => void {
//   return () => {
//     ga('create', config.googleAnalytics, 'auto');
//   };
// }

export function HttpLoaderFactory() {
  return new TranslateHttpLoader(
    new HttpClient(
      new HttpXhrBackend({
        build: () => new XMLHttpRequest(),
      })
    )
  );
}

registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(
      APP_ROUTES,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxStripe(environment.stripeKey),

    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),

    importProvidersFrom(HammerModule),

    {
      provide: ENVIRONMENT_TOKEN,
      useFactory: () => environment,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeGoogleAnalyticsFactory,
    //   multi: true,
    //   deps: [AppConfiguration],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFacebookPixelFactory,
      multi: true,
      deps: [AppConfiguration],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigationFactory,
      deps: [NavigationService],
      multi: true,
    },
    {
      provide: COMMON_BASE_URL,
      useFactory: (config: AppConfiguration) => config.baseURL,
      deps: [AppConfiguration],
    },
    {
      provide: STRETCHITLIVE_PIXEl_URL,
      useFactory: (config: AppConfiguration) => config.stretchitivePixelURL,
      deps: [AppConfiguration],
    },
    {
      provide: SIZES_TOKEN,
      useValue: new AppSizes(),
    },
    {
      provide: PROGRAMS_NEW_BASE_URL,
      useFactory: (config: AppConfiguration) => config.programsNewUrl,
      deps: [AppConfiguration],
    },
    {
      provide: API_NEW_BASE_URL,
      useFactory: (config: AppConfiguration) => config.apiNewBaseUrl,
      deps: [AppConfiguration],
    },
    {
      provide: FEEDBACK_URL,
      useFactory: (config: AppConfiguration) => config.feedbackURL,
      deps: [AppConfiguration],
    },
  ],
}).catch(err => console.log(err));
