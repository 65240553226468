import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageContextService } from '../../context/storage-context/storage-context.service';
import { checkWasAuthSuccessRedirect } from '../services/auth.utils';

export const AuthorizationGuard: CanActivateFn = async () => {
  const storage = inject(StorageContextService);
  const router = inject(Router);

  if (window.location.pathname.includes('/success') || checkWasAuthSuccessRedirect()) return true;

  if (storage.getItem('access_token')) {
    await router.navigateByUrl('/app/home');
    return false;
  }
  return true;
};
