import { Route } from '@angular/router';
import { InfluencersMatcher } from '../../shared/matchers/influencers.matcher';

export const PROGRAM_LANDING_ROUTES: Route[] = [
  {
    path: 'get-program',
    loadChildren: () => import('./lazy-routes/get-program').then(m => m.route),
  },
  {
    matcher: InfluencersMatcher,
    loadChildren: () => import('./lazy-routes/influencers').then(m => m.route),
  },
  {
    path: '30daysfree',
    loadChildren: () => import('./lazy-routes/30-days-free').then(m => m.route),
  },
  {
    path: '1monthfree',
    loadChildren: () => import('./lazy-routes/1-month-free').then(m => m.route),
  },
  {
    path: 'nextjump',
    loadChildren: () => import('./lazy-routes/nextjump').then(m => m.route),
  },
  {
    path: 'special-cancelled-trial',
    loadChildren: () => import('./lazy-routes/special-offer-no-trial').then(m => m.route),
  },
] as const;
