import { CanActivateFn } from '@angular/router';

export const SaleGuard: CanActivateFn = (_, state) => {
  const path = state.url.replace('/sale?', '').replace('/sale', '');

  const redirect = `${window.location.origin}/app/packages?action=upgrade`;

  window.location.href = path ? `${redirect}&${path}` : redirect;
  return false;
};
