<app-sprite-svg />

<router-outlet [class.opacity-0]="globalLoader.isHideContent()" />

@if (loading() || localeService.reloading() || globalLoader.isLoading()) {
  <div
    class="loading"
    [class.!bg-white]="globalLoader.isHideContent()">
    <app-loading-logo
      class="block transition-opacity duration-1000"
      [class.opacity-100]="showLoadingLogo()"
      [class.opacity-0]="!showLoadingLogo()" />

    <app-loading />
  </div>
}

<div
  #dialogContainer
  style="height: 0">
</div>

<div
  #notificationContainer
  style="height: 0">
</div>

@if (!cookieNoticeDisabled) {
  <app-cookie-notice
    (disableCookieNotice)="disableCookieNotice()"
    [class.opacity-0]="globalLoader.isHideContent()" />
}
