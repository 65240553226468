export class AppSizes {
  readonly screens = {
    // mobile
    mobile: '0px',
    xs: '360px',
    // tablet
    tablet: '600px',
    sm: '600px',
    md: '768px',
    // macbook
    macbook: '960px',
    lg: '960px',
    xl: '1200px',
    // desktop
    desktop: '1440px',
  };
}

let singleton: AppSizes;

export class AppSizesResolver extends AppSizes {
  static resolveScreens() {
    if (!singleton) {
      singleton = new AppSizes();
    }
    return singleton.screens;
  }
}
